<template>
  <div>
    <!-- <div class="tmHeader__header_small">{{ header }}</div> -->
    <show-case :sku="gfGreenDfruitsCards" />
  </div>
</template>

<script>
import ShowCase from "../components/ShowCase.vue";

import GreenDFapricot from "../assets/GFGreen/GFGreenDF/GF_Green_sweets_apricot_in_chokolate_front.jpg";
import GreenDFbanana from "../assets/GFGreen/GFGreenDF/GF_Green_sweets_banana_in_chocolate_front.jpg";
import GreenDFdate from "../assets/GFGreen/GFGreenDF/GF_Green_figs_in_chocolate_front.jpg";

export default {
  name: "GFGreenDfruits",
  components: {
    ShowCase,
  },
  data() {
    return {
      header: "Сухофрукты в шоколаде",
      gfGreenDfruitsCards: [
        {
          picture: GreenDFapricot,
          text: "GOOD FOOD Green Абрикос в шоколаде 150 г",
          id: 1,
          description:
            "Состав: абрикос  сушеный без косточки, шоколадная масса (сахар, масло какао, тёртое какао, какао -порошок, эмульгатор - лецитин соевый, ванильный порошок (молотые стручки ванили)).<br>Содержание общего сухого остатка какао - продуктов в шоколадной массе -  не менее 44%, содержание какао - масла не менее 34,5%.<br>Продукт может содержать следы молочного белка, фрагменты косточек, следы арахиса, кунжута и орехов.<br>Пищевая ценность в 100 г: Белки - 3,3 г, Жиры 14,5 г, Углеводы 57,3 г.<br>Энергетическая ценность 373 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: GreenDFbanana,
          text: "GOOD FOOD Green Банан в шоколаде 150 г",
          id: 2,
          description:
            "Состав: банан  сушеный, шоколадная масса (сахар, масло какао, тёртое какао, какао - порошок, эмульгатор - лецитин соевый, ванильный порошок (молотые стручки ванили)).<br>Содержание общего сухого остатка какао - продуктов в шоколадной массе -  не менее 44%, содержание какао - масла не менее 34,5%.<br>Продукт может содержать следы молочного белка, следы арахиса, кунжута и орехов.<br>Пищевая ценность в 100 г: Белки - 4,5 г, Жиры 13,0 г, Углеводы 61,0 г.<br>Энергетическая ценность 379 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 9 месяцев.<br>ТУ 9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: GreenDFdate,
          text: "GOOD FOOD Green Финик в шоколаде 150 г",
          id: 3,
          description:
            "Состав: финик  сушеный (финик сушеный без косточки, глюкозный сироп), шоколадная масса (сахар, масло какао, тёртое какао, какао - порошок, эмульгатор - лецитин соевый, ванильный порошок (молотые стручки ванили)).<br>Содержание общего сухого остатка какао - продуктов в шоколадной массе -  не менее 44%, содержание какао - масла не менее 34,5%.<br>Продукт может содержать следы молочного белка, следы арахиса, кунжута и орехов.<br>Пищевая ценность в 100 г: Белки - 3,2 г, Жиры 13,8 г, Углеводы 51,7 г.<br>Энергетическая ценность 379 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 9 месяцев.<br>ТУ 9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
      ],
    };
  },
};
</script>
